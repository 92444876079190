import * as React from "react";
import PropTypes from "prop-types";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import ogimage from "../assets/images/Foundation.png";


function Seo({ description, title, image, author, siteUrl }) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl: defaultSiteUrl,
    author: defaultAuthor,
    imageUrl: defaultImageUrl,
  } = useSiteMetadata();

  const metaDescription = description || defaultDescription;
  const metaTitle = title || defaultTitle;
  const metaImage = image || defaultImageUrl;
  const metaAuthor = author || defaultAuthor;
  const metaUrl = siteUrl || defaultSiteUrl;

  return (
    <>
      <html lang="en-US" />
      <title>{metaTitle}</title>
      {/*   <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" /> */}
      <link rel="canonical" href={metaUrl} />
      <link href="https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@200;300;400;700&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap" rel="stylesheet" />
      <meta http-equiv="language" content="en-US"></meta>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={ogimage} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={metaAuthor} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
    </>
  );
}

Seo.defaultProps = {
  description: ``,
  image: ``,
};

Seo.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Seo;
