import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Seo from '../components/Seo'
import { IsrService } from '../services/SEOServices'
import { SeoLoaderStatus } from '../Store/Action/Action'
import { getSessionstoreData } from '../Store/GetData'
import { Sessionstore } from '../Store/StoreData'
import SeoComponent from './SeoComponent'

export default function SeoTest({ data, serverData }) {
  console.log(data, '====', serverData)
  return <div>SeoTest</div>
}

export const Head = () => {
  return (
    <SeoComponent
      title="seo test page"
      description="seo test description"
      // image="https://ik.imagekit.io/sbbplalyp/shinchan_X_0ZG1MaQu.png"
      language="en"
      url="https://creations.ableaura.com/SeoTest/"
    />
  )
}

// export const pageQuery = graphql`
//   query PageData {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `

// export async function getServerData() {
//   try {
//     const { data } = await IsrService()
//     console.log(data?.data?.seo?.title, '==responsetest')
//     return {
//       props: {
//         dogImage: 'test',
//       },
//     }
//   } catch (err) {
//     console.log(err, '==error')
//   }

//   // const res = await fetch(`https://dog.ceo/api/breeds/image/random`)
//   // const data = await res.json()
// }
