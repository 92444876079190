import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Seo from '../components/Seo'
import { homepage } from '../services/SEOServices'

export default function SeoHome() {
  return <div>SeoDescription</div>
}

export const Head = () => {
  return (
    <Seo
      title="seo home"
      description="seo home description"
      image="https://ik.imagekit.io/sbbplalyp/doreamon_VYK5Bec0o.png"
      twitter_image="https://ik.imagekit.io/sbbplalyp/doreamon_VYK5Bec0o.png"
    />
  )
}
