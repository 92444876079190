import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-image-crop/src/ReactCrop.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'material-icons/iconfont/material-icons.css'
import 'react-datetime/css/react-datetime.css'
import 'react-multi-carousel/lib/styles.css'
import './common.scss'
import App from './app'
import Seo from '../components/Seo'

const IndexPage = () => {
  return <App />
}
export const Head = () => {
  return <Seo />
}

export default IndexPage
