import React, { useCallback, useEffect, useState } from "react";
import SportsHeading from "./sportsheading";
import Faq from "../../components/Acedamycomponets/FAQS";
import AcedamyHeading from "../../components/Acedamycomponets/Acedamyheading";
import Blogcarousel from "../../components/Acedamycomponets/blogsCarousel";
import EnroleButton from "../../components/Acedamycomponets/EnroleButton";

function Sports() {
 return(
      <div className="mainsec">
        <SportsHeading/>
        <AcedamyHeading />
        <hr />
        <div style={{ paddingTop: "20px" }}>
          <h5 className="resourceheading">RESOURCE BLOGS</h5>
          <Blogcarousel />
        </div>
        <div>
        
        </div>
        <Faq />
        <EnroleButton />
      </div>
    )
    }

export default Sports;
