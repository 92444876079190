import Cookies from 'universal-cookie'

export const getCookieData = key => {
  const cookies = new Cookies()
  return cookies.get(key)
}

export const getLocalstoreData = key => {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem(key))
  } else {
    return 'data'
  }
}

export const getSessionstoreData = key => {
  if (typeof window !== 'undefined') {
    return JSON.parse(sessionStorage.getItem(key))
  } else {
    return 'data'
  }
}

export function getCookie(cname) {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
