import Cookies from 'universal-cookie'

export const Cookiestore = (key, data) => {
  const cookies = new Cookies()
  cookies.set(key, data, { path: '/', maxAge: 31536000 })
}

export const Localstore = (key, data) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(data))
  }
}

export const Sessionstore = (key, data) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(key, JSON.stringify(data))
  }
}

export function setCookie(cname, cvalue, exdays) {
  if (typeof window !== 'undefined') {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }
}
