exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-sports-js": () => import("./../../../src/pages/academy/sports.js" /* webpackChunkName: "component---src-pages-academy-sports-js" */),
  "component---src-pages-academy-sportsheading-js": () => import("./../../../src/pages/academy/sportsheading.js" /* webpackChunkName: "component---src-pages-academy-sportsheading-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-seo-component-js": () => import("./../../../src/pages/SeoComponent.js" /* webpackChunkName: "component---src-pages-seo-component-js" */),
  "component---src-pages-seo-home-js": () => import("./../../../src/pages/SeoHome.js" /* webpackChunkName: "component---src-pages-seo-home-js" */),
  "component---src-pages-seo-settings-js": () => import("./../../../src/pages/SeoSettings.js" /* webpackChunkName: "component---src-pages-seo-settings-js" */),
  "component---src-pages-seo-test-js": () => import("./../../../src/pages/SeoTest.js" /* webpackChunkName: "component---src-pages-seo-test-js" */)
}

