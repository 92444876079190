const initialAuthState = {
  AccessToken: '',
  HeaderValue: 'All',
  data: [],
  disability: {
    categoryData: [],
    subcategoryData: [],
    selectedData: [],
  },
  subcategory: [],
  category: [],
  subcategorydata: [],
  cartContents: [],
  cartStatus: false,
  transaction: false,
  ordercmsData: [],
  walletCMSData: [],
  HomeCMSData: [],
  createListingCategory: [],
  SearchData: '',
  Successmodal: {
    show: false,
    msg: '',
  },
  FailureModal: {
    show: false,
    msg: '',
  },
  PaymentModal: {
    show: false,
  },
  aboutCMS: [],
  csrData: [],
  isrData: [],
  beneuserData: null,
  ticketData: null,
  beneuserWishlist: [],
  endorseuserData: null,
  activeTab: -1,
  goodwillData: [],
  relatedServiceData: [],
  isprogressOrder: false,
  accountUpdated: false,
  benefitayListingPagenumber: 1,
  benefitaryListing: [],
  cartData: [],
  dealsData: [],
  categoryUpdated: false,
  categoryData: {
    search_term: null,
    category: null,
    classification: null,
    deals_check: 0,
  },
  serviceValue: [],
  bankList: [],
  beneficiaryMasterData: [],
  beneficiaryPagenumber: 1,
  SEOMeta: {},
  seoLoaderStatus: true,
  isSignoutBtnClicked: false,
};

const handleSignoutBtn = (state, action) => {
  return {
    ...state,
    isSignoutBtnClicked: action.data,
  };
};

const SeoLoader = (state, action) => {
  return {
    ...state,
    seoLoaderStatus: action.isLoaderStatus,
  };
};

const UserStore = (state, action) => {
  return {
    ...state,
    data: action.data,
  };
};

const updateSEOMETA = (state, action) => {
  return {
    ...state,
    SEOMeta: action.SEOMeta,
  };
};

const Disability = (state, action) => {
  return {
    ...state,
    disability: action.disability,
  };
};
const SubCategory = (state, action) => {
  return {
    ...state,
    subcategory: action.subcategory,
  };
};

const Category = (state, action) => {
  return {
    ...state,
    category: action.category,
  };
};

const SetAccessToken = (state, action) => {
  return {
    ...state,
    AccessToken: action.AccessToken,
  };
};

const SetCartConfigure = (state, action) => {
  return {
    ...state,
    cartContents: action.cartContents,
  };
};

const SetCartStatus = (state, action) => {
  return {
    ...state,
    cartStatus: action.cartStatus,
  };
};

const SetTransactionStatus = (state, action) => {
  return {
    ...state,
    transaction: action.transaction,
  };
};

const SetOrderCms = (state, action) => {
  return {
    ...state,
    ordercmsData: action.ordercmsData,
  };
};

const SetWalletCms = (state, action) => {
  return {
    ...state,
    walletCMSData: action.walletCMSData,
  };
};

const SetHomePageCms = (state, action) => {
  return {
    ...state,
    HomeCMSData: action.HomeCMSData,
  };
};

const SuccessModalShow = (state, action) => {
  return {
    ...state,
    Successmodal: action.Successmodal,
  };
};

const PaymentModalShow = (state, action) => {
  return {
    ...state,
    PaymentModal: action.PaymentModal,
  };
};

const FailureModalShow = (state, action) => {
  return {
    ...state,
    FailureModal: action.FailureModal,
  };
};
const aboutCMSData = (state, action) => {
  return {
    ...state,
    aboutCMS: action.aboutCMS,
  };
};

const CreateListingData = (state, action) => {
  return {
    ...state,
    createListingCategory: action.createListingCategory,
  };
};

const CreateListingSearch = (state, action) => {
  return {
    ...state,
    SearchData: action.SearchData,
  };
};

const handleCSRData = (state, action) => {
  return {
    ...state,
    csrData: action.csrData,
  };
};

const handleISRData = (state, action) => {
  return {
    ...state,
    isrData: action.isrData,
  };
};

const handleBENE_UserData = (state, action) => {
  return {
    ...state,
    beneuserData: action.beneuserData,
  };
};

const handleticket_Data = (state,action) =>{
  return {
    ...state,
    ticketData: action.ticketData,
   
  }
}


const handleBENE_Wishlist = (state, action) => {
  return {
    ...state,
    beneuserWishlist: action.beneuserWishlist,
  };
};

const handleEndorse_UserData = (state, action) => {
  return {
    ...state,
    endorseuserData: action.endorseuserData,
  };
};

const setActiveTab = (state, action) => {
  return {
    ...state,
    activeTab: action.activeTab,
  };
};

const handleGoodwillData = (state, action) => {
  return {
    ...state,
    goodwillData: action.data,
  };
};
const RelatedServiceData = (state, action) => {
  return {
    ...state,
    relatedServiceData: action.relatedServiceData,
  };
};

const changeProgressOrder = (state, action) => {
  return {
    ...state,
    isprogressOrder: action.isprogressOrder,
  };
};

const AccountUpdateStatus = (state, action) => {
  return {
    ...state,
    accountUpdated: action.accountUpdated,
  };
};
const handleBenefitaryListing = (state, action) => {
  return {
    ...state,
    benefitaryListing: action.data,
  };
};
const handleBenefitaryListingPagenumber = (state, action) => {
  return {
    ...state,
    benefitayListingPagenumber: action.benefitayListingPagenumber + 1,
  };
};
const UpdateCart = (state, action) => {
  return {
    ...state,
    cartData: action.cartData,
  };
};
const DealsData = (state, action) => {
  return {
    ...state,
    dealsData: action.dealsData,
  };
};

const SearchCategoryData = (state, action) => {
  return {
    ...state,
    categoryData: action.categoryData,
  };
};

const SetCategoryUpdated = (state, action) => {
  return {
    ...state,
    categoryUpdated: action.categoryUpdated,
  };
};

const SetHeaderValue = (state, action) => {
  return {
    ...state,
    HeaderValue: action.HeaderValue,
  };
};

const SetRelatedService = (state, action) => {
  return {
    ...state,
    serviceValue: action.serviceValue,
  };
};

const handleBankList = (state, action) => {
  return {
    ...state,
    bankList: action.data,
  };
};

const handleBeneficiaryMasterdata = (state, action) => {
  return {
    ...state,
    beneficiaryMasterData: action.data,
  };
};

const handleBeneficiaryPageNumber = (state, action) => {
  return {
    ...state,
    beneficiaryPagenumber: action.data,
  };
};

function rootReducer(state = initialAuthState, action) {
  switch (action.type) {
    case 'ACCESS_TOKEN':
      return SetAccessToken(state, action);
    case 'USER_DATA':
      return UserStore(state, action);
    case 'HEADER_VALUE':
      return SetHeaderValue(state, action);
    case 'DISABILITY_CATEGORY':
      return Disability(state, action);
    case 'HEADER_CATEGORY':
      return Category(state, action);
    case 'HEADER_SUB_CATEGORY':
      return SubCategory(state, action);
    case 'USER_CART':
      return SetCartConfigure(state, action);
    case 'USER_CART_STATUS':
      return SetCartStatus(state, action);
    case 'USER_TRANSACTION_STATUS':
      return SetTransactionStatus(state, action);
    case 'USER_ORDER_CMS':
      return SetOrderCms(state, action);
    case 'USER_WALLET_CMS':
      return SetWalletCms(state, action);
    case 'HOME_PAGE_CMS':
      return SetHomePageCms(state, action);
    case 'SUCCESS_MODAL_SHOW':
      return SuccessModalShow(state, action);
    case 'FAILURE_MODAL_SHOW':
      return FailureModalShow(state, action);
    case 'PAYMENT_MODAL_SHOW':
      return PaymentModalShow(state, action);
    case 'ABOUT_CMS_DATA':
      return aboutCMSData(state, action);
    case 'CREATE_LISTING_CATEGORY':
      return CreateListingData(state, action);
    case 'CREATE_LISTING_SEARCH':
      return CreateListingSearch(state, action);
    case 'CSR_DATA':
      return handleCSRData(state, action);
    case 'ISR_DATA':
      return handleISRData(state, action);
    case 'BENEFITIORY_USER_DATA':
      return handleBENE_UserData(state, action);
    case 'TICKET_DATA' :
     return handleticket_Data(state,action);
    case 'BENEFITIORY_WISHLIST_DATA':
      return handleBENE_Wishlist(state, action);
    case 'ENDORSE_USER_DATA':
      return handleEndorse_UserData(state, action);
    case 'SET_ACTIVE_TAB':
      return setActiveTab(state, action);
    case 'GOODWILL_DATA':
      return handleGoodwillData(state, action);
    case 'RELATED_SERVICE_DATA':
      return RelatedServiceData(state, action);
    case 'ACCESS_TOKEN':
      return SetAccessToken(state, action);
    case 'USER_DATA':
      return UserStore(state, action);
    case 'DISABILITY_CATEGORY':
      return Disability(state, action);
    case 'HEADER_CATEGORY':
      return Category(state, action);
    case 'HEADER_SUB_CATEGORY':
      return SubCategory(state, action);
    case 'USER_CART':
      return SetCartConfigure(state, action);
    case 'USER_CART_STATUS':
      return SetCartStatus(state, action);
    case 'USER_TRANSACTION_STATUS':
      return SetTransactionStatus(state, action);
    case 'USER_ORDER_CMS':
      return SetOrderCms(state, action);
    case 'USER_WALLET_CMS':
      return SetWalletCms(state, action);
    case 'HOME_PAGE_CMS':
      return SetHomePageCms(state, action);
    case 'SUCCESS_MODAL_SHOW':
      return SuccessModalShow(state, action);
    case 'FAILURE_MODAL_SHOW':
      return FailureModalShow(state, action);
    case 'ABOUT_CMS_DATA':
      return aboutCMSData(state, action);
    case 'CREATE_LISTING_CATEGORY':
      return CreateListingData(state, action);
    case 'CREATE_LISTING_SEARCH':
      return CreateListingSearch(state, action);
    case 'CSR_DATA':
      return handleCSRData(state, action);
    case 'ISR_DATA':
      return handleISRData(state, action);
    case 'BENEFITIORY_USER_DATA':
      return handleBENE_UserData(state, action);
    case 'BENEFITIORY_WISHLIST_DATA':
      return handleBENE_Wishlist(state, action);
    case 'ENDORSE_USER_DATA':
      return handleEndorse_UserData(state, action);
    case 'SET_ACTIVE_TAB':
      return setActiveTab(state, action);
    case 'GOODWILL_DATA':
      return handleGoodwillData(state, action);
    case 'RELATED_SERVICE_DATA':
      return RelatedServiceData(state, action);
    case 'CHANGE_PROGRESS_ORDER':
      return changeProgressOrder(state, action);
    case 'ACCOUNT_UPDATE_STATUS':
      return AccountUpdateStatus(state, action);
    case 'BENEFITARY_LISTING':
      return handleBenefitaryListing(state, action);
    case 'BENEFITARY_PAGE_NUMBER':
      return handleBenefitaryListingPagenumber(state, action);
    case 'CART_UPDATE':
      return UpdateCart(state, action);
    case 'DEALS_VALUE':
      return DealsData(state, action);
    case 'SEARCH_CATEGORY_DATA':
      return SearchCategoryData(state, action);
    case 'SET_CATEGORY_UPDATED':
      return SetCategoryUpdated(state, action);
    case 'SET_RELATEDSERVICE_DATA':
      return SetRelatedService(state, action);
    case 'BANK_LIST':
      return handleBankList(state, action);
    case 'BENEFICIARY_MASTER_DATA':
      return handleBeneficiaryMasterdata(state, action);
    case 'BENEFICIARY_PAGE_NUMBER':
      return handleBeneficiaryPageNumber(state, action);
    case 'UPDATE_SEO_META':
      return updateSEOMETA(state, action);
    case 'SEO_LOADER_STATUS':
      return SeoLoader(state, action);
    case 'IS_SIGNOUT_CLICKED':
      return handleSignoutBtn(state, action);
    default:
      return state || null;
  }
}

export { rootReducer };
