import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                    description
                    imageUrl
                    title
                    twitterUsername
                    url
                }
            }
        }
    `);
  return data.site.siteMetadata;
};
